$(document).ready(function() {
    jTimer.init();
});

var jTimer = {

    init: function() {

        if (!$('.jtimer').length) return;

        $('.jtimer').each(this.createTimer.bind(this));
    },

    createTimer: function(i, elem) {
        new this.Timer(elem);
    },

    declOfNum: function(n, titles){
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
    }
}

jTimer.Timer = function(elem) {
    this.elem = elem || null;
    this.render();
}

jTimer.Timer.prototype.render = function() {
    var eventDate = $(this.elem).data('event-date'),
        arrEventDate = eventDate.split('T'),
        date = arrEventDate[0],
        time = arrEventDate[1],
        arrDate = date.split('.'),
        arrTime = time.split(':');

    this.elem.eDate = new Date(arrDate[2], arrDate[1] - 1, arrDate[0], arrTime[0], arrTime[1], arrTime[2]);

    this.elem.timer = setInterval(this.setTime.bind(this), 1000);
}

jTimer.Timer.prototype.setTime = function() {
    var year = 0, month = 0, day = 0, hh = 0, mm = 0, ss = 0, diffReduced = 0;
    var d = new Date();
    var diff = this.elem.eDate - d;
    if (diff >= 0) {
        diffReduced = Math.round(diff / 1000);
        ss = diffReduced % 60;
        diffReduced = (diffReduced - ss) / 60;
        mm = diffReduced % 60;
        diffReduced = (diffReduced - mm) / 60;
        hh = diffReduced % 24;
        diffReduced = (diffReduced - hh) / 24;
        day = diffReduced;
    }

    $('.jtimer-label-day', this.elem).html(jTimer.declOfNum(day,['день','дня','дней']));
    $('.jtimer-label-hour', this.elem).html(jTimer.declOfNum(hh,['час','часа','часов']));
    $('.jtimer-label-minute', this.elem).html(jTimer.declOfNum(mm,['минута','минуты','минут']));
    $('.jtimer-label-second', this.elem).html(jTimer.declOfNum(ss,['секунда','секунды','секунд']));

    if (ss < 10) ss = '0' + ss;
    if (mm < 10) mm = '0' + mm;
    if (hh < 10) hh = '0' + hh;

    $('.jtimer-day', this.elem).html(day);
    $('.jtimer-hour', this.elem).html(hh);
    $('.jtimer-minute', this.elem).html(mm);
    $('.jtimer-second', this.elem).html(ss);

    if(diff <= 0){

    }
}